
import { BrowserRouter as Router,
  Route, 
  Routes
} from 'react-router-dom';

import './App.css';
import Footer from './components/footer/Footer';
import Navbar from './components/navbar/Navbar';
import Home from './components/pages/Home';

import Recipes from './components/pages/Recipes';
import Settigns from './components/pages/Settigns';


function App() {
  return (
    <Router>
       <Navbar />
      <div className='container main'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/recipes' element={<Recipes />} />
          <Route path='/settings' element={<Settigns />} />
        </Routes>

      </div>
      <Footer />

    </Router>

  );
}

export default App;
