import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import { useState } from 'react'
import Sidebar from '../sidebar/Sidebar';
import { faHome, faList, faCog } from "@fortawesome/free-solid-svg-icons"

const Navbar = () => {
    const location = useLocation()

    const [showSidebar, setShowsiebar] = useState(false);
    const links = [
        {
            name: "Home",
            path: '/',
            icon: faHome
        },
        {
            name: "Recipes",
            path: '/recipes',
            icon: faList
        },
        {
            name: "settings",
            path: '/settings',
            icon: faCog
        }
    ];

 function closeSidebar(){
        setShowsiebar(false)
    }   



  return (
<>
<div className='navbar container'>
        <Link to="/" className='logo'>F<span>oo</span>dtasteHub</Link>
        <div className='nav-links'>
            {links.map(link => (
                <Link
                className={location.pathname === link.path ? "active": ""}
                 to={link.path} key={link.name}>{link.name}</Link>

            ))}
            
        </div>
        <div onClick={() => setShowsiebar(true)}
        className='sidebar-btn'>
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>

        </div>


    </div>
    {showSidebar && <Sidebar close={closeSidebar} links={links} />}

    
</>
  )
}

export default Navbar