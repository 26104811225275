import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Previous = () => {

    const searches = [
        'pizza',
        'burger',
        'cookies',
        'juice',
        'baryani',
        'soup',
    ]
  return (
    <div>
         <div className='previous-searchs section'>
        <h2>Previous Searches</h2>
        <div className='previous-searchs-content'>
            {searches.map(search => (
                <div className='search-item'>
                    {search}
                </div>   
            ))}

        </div>
        <div className='search-box'>
            <input type="text" placeholder='Search' name="" id="" />
            <button className='btn'><FontAwesomeIcon icon={faSearch} /></button>

        </div>

    </div>
    </div>
  )
}

export default Previous