import React from 'react'

const Customimg = ({Imgsrc, pt}) => {
  return (
    <div className='custom-image'
    style={
        {paddingTop: pt}
    }>
    <img src={Imgsrc} alt="" />

    </div>
  )
}

export default Customimg