import React from 'react'
import Chiefs from '../chief/Chiefs';
import Hero from '../hero/Hero';
import ImproveSkill from '../improveSkills/ImproveSkill';
import Quest from '../Quet/Quest';


const Home = () => {
  return (
    <div>
        <Hero />
        <ImproveSkill />
        <Quest />
        <Chiefs />
    </div>
  )
}

export default Home