import React from 'react'

const Footer = () => {
  return (
    <div className="footer container">
            <div className="footer-section">
                <p className="title">foodtastehub.com</p>
                <p>FoodtasteHub is a place where you can please your soul and tummy with delicious food recepies of all cuisine. And our service is absolutely free.</p>
                <p>&copy; 2021 | All Rights Reserved</p>
            </div>
            <div className="footer-section">
                <p className="title">Contact Us</p>
                <p>FoodtasteHub@gmail.com</p>
                <p>+92 99320323</p>
                <p>2393 Street Lahore</p>
            </div>
            <div className="footer-section">
                <p className="title">Socials</p>
                <p>Facebook</p>
                <p>Twitter</p>
                <p>Instagram</p>
            </div>
        </div>
  )
}

export default Footer